<template>
  <div class="">
    <HeaderVue heading="Collections" class="mb-3">
      <template v-slot:buttons>
        
          <AddCollectionModal ref="addCollectionModal" :categoryTitle="{singular:'collection', plural:'collections'}" @submit="saveCollection($event)" :isLoading="isLoadAddCollection" ></AddCollectionModal>

          <button
            v-if="getNavbarData.userType == 'shopify'"
            class="btn px-3  py-2 ml-2 shopify-btn"
            @click="openShopifyCollectionModal()">
            import from <i class="fab fa-shopify"></i>
          </button>
          <SyncWixCollections v-if="getNavbarData.userType == 'wix'"></SyncWixCollections>
          <SyncBigCommerceCollections v-if="getNavbarData.userType == 'bc'" class="ml-2"></SyncBigCommerceCollections>
      </template>
    </HeaderVue>

    <div class="card border-radius">
      <div class="card-body" style="overflow:auto">
        <div class="">
          <FilterBox
            :search="true"
            v-model.trim="titleInput"
            placeholder="Search Collections..."></FilterBox>
          <div class="loader d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center" v-if="isLoading">
              <b-spinner small style="color: var(--primary-color)"></b-spinner>
              <span class="ml-2">Loading Collections</span>
            </div>
          </div>
          <div class="skeleton" v-if="isLoadingFirstTime">
            <b-skeleton-table
              class="py-3"
              :rows="5"
              :columns="4"
              :table-props="{
                bordered: true,
                striped: true,
              }"></b-skeleton-table>
          </div>
          <div class="data-table" v-else-if="items.length > 0">
            <b-table
              head-class="text-center"
              class="leadTable res"
              :responsive="true"
              :stickyColumn="true"
              hover
              :fields="fields"
              :items="items">
              <template #head(checkbox)>
                <b-form-checkbox
                  v-model="selectAllQuizellCollections"
                  size="lg"
                  @input="toggleQuizellCollections()"
                  class="checkbox"></b-form-checkbox>
              </template>
              <template #cell(checkbox)="data">
                <b-form-checkbox
                  v-model="selectedQuizellCollections"
                  :value="data.item.id"
                  size="lg"
                  class="checkbox"></b-form-checkbox>
              </template>

              <template #cell(title)="data">
                <div class="d-flex align-items-center">
                  <lazy-image
                    class="circle-image mr-2"
                    :src="
                      data.item.image
                        ? data.item.image
                        : 'https://images.quizell.com/website/default_start_page.png'
                    "
                    :key="data.item.image"></lazy-image>
                  <span class="text-hover-underline"  @click="()=>{
                    editCollectionId=data.item.id
                    $refs.EditCollectionModal.open({title:data.item.title,image:data.item.image})
                  }">{{ data.item.title }}</span>
                </div>
              </template>
              <template #cell(actions)="data">
                <b-dropdown  dropright class="" id="collection-detail">
                  <template slot="button-content">
                    <div class="p-2">
                      <i class="text-dark fa-solid fa-ellipsis-vertical"></i>
                    </div>
                  </template>
                  <b-dropdown-item class="topbarLink d-flex align-items-center" @click.stop="$refs.viewProducts.openModal(data.item.id)">
                    View Products</b-dropdown-item
                  >
                  <b-dropdown-item  @click="()=>{
                    editCollectionId=data.item.id
                    $refs.EditCollectionModal.open({title:data.item.title,image:data.item.image})
                  }" class="topbarLink d-flex align-items-center">
                    Edit Collection</b-dropdown-item
                  >
                  
                  <b-dropdown-item v-if="getUserType=='shopify'" @click="()=>{
                    syncCollection=data.item
    $refs.syncCollection.open();
                  }" class="topbarLink d-flex align-items-center">
                    Sync Product</b-dropdown-item
                  >
                </b-dropdown>
              </template>
            </b-table>
          </div>
          <template v-else>

<div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable   :showButton="false">
<template #title>
No collection found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
           
          <div class="">
            <QuizellPagination  :total-rows="totalRows" :currentPage="currentPage"
                  :per-page="perPage"   v-if="items.length" @update="updatePagination" @UpdatePerPage="updatePerPage"></QuizellPagination>
          </div>
        </div>
      </div>
    </div>

    <!-- Shopify Modal -->
    <ModalVue
      ref="Modal"
      id="copyShopifyCollections"
      title="Import from Shopify"
      size="lg">
      <div class="my-2" :class="!isLoadingSync ? 'd-none' : 'd-block'">
        <ProgressbarVue ref="progressbar"></ProgressbarVue>
        <span
          class="text-success text-center"
          style="font-size: 12px; text-align: center">
          Collections syncing soon, continue tasks uninterrupted.
        </span>
      </div>
      <FilterBox
              :search="true"
              placeholder="Search Collections..."
              @input="searchCollectionQueryHandle($event)"></FilterBox>
          
      <div class="loader px-2">
        <div
          class="d-flex py-2"
          :class="[
            isLoadingShopifyCollections
              ? 'justify-content-between'
              : 'justify-content-start',
          ]">
          <div
            class="d-flex align-items-center"
            v-if="isLoadingShopifyCollections">
            <b-spinner small style="color: var(--primary-color)"></b-spinner>
            <span class="ml-2">Loading Collections</span>
          </div>
          <div class="" v-if="shopifySelectedCollections.length">
            Selected Collections : {{ shopifySelectedCollections.length }}
          </div>
        </div>
      </div>
      <b-skeleton-table
        v-if="isLoadingShopifyCollectionFirstTime"
        :rows="5"
        :columns="3"
        :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
      <div class="data-table" v-else-if="shopifyCollections.length">
        <div
          class=""
          style="max-height: 55vh; min-height: 55vh; overflow: auto">
          <b-table
            head-class="text-center"
            class="leadTable"
            :stickyColumn="true"
            hover
            :fields="shopifyCollectionsFields"
            :items="shopifyCollections">
            <template #head(checkbox)>
              <b-form-checkbox
                size="lg"
                :disabled="
                  isLoadingShopifyCollections ||
                  isLoadingSelectedShopifyCollections ||
                  isLoadingSync
                "
                v-model="shopifySelectAllCheck"
                @change="shopifyCollectionSelectAllToggle($event)"
                class="checkbox selectAll"></b-form-checkbox>
            </template> 
            <template #cell(productsCount)="data">
             {{ data.item.productsCount.count }}
            </template>
            <template #cell(productsCount)="data">
             {{ data.item.productsCount.count }}
            </template>
            <template #cell(checkbox)="data">
              <b-form-checkbox
                :value="data.item"
                v-model="shopifySelectedCollections"
                :disabled="
                  isLoadingShopifyCollections ||
                  isLoadingSelectedShopifyCollections ||
                  isLoadingSync
                "
                size="lg"
                class="checkbox"></b-form-checkbox>
            </template>
          </b-table>
        </div>
      </div>
      <template v-else>

<div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable   :showButton="false">
<template #title>
No collection found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
           
      <div class="d-flex justify-content-between mt-3">
        <ShopifyPagination
          :hasNext="pageInfo.hasNextPage"
          @onNext="
            () => {
              queryObject.before = '';
              queryObject.after = pageInfo.endCursor;
              getCollectionsFromShopify();
            }
          "
          :hasPrevious="pageInfo.hasPreviousPage"
          @onPrevious="
            () => {
              queryObject.after = '';
              queryObject.before = pageInfo.startCursor;
              getCollectionsFromShopify();
            }
          "></ShopifyPagination>
          <div class="">
          <div
            class="d-flex py-2 py-lg-0 justify-content-lg-end w-100 flex-row align-items-center"
            >
            <BorderButton
              :custom="true"
              @click="syncSelected()"
              :disabled="
                isLoadingSync ||
                isLoadingShopifyCollections ||
                isLoadingSelectedShopifyCollections
              "
              :isLoading="isLoadingSelectedShopifyCollections"
              style="border-radius: 4px !important"
              v-if="shopifySelectedCollections.length"
              class="secondary-color-button mr-3"
             
              >Sync Selected</BorderButton
            >
          
            <button
              :disabled="
                isLoadingSync ||
                isLoadingShopifyCollections ||
                isLoadingSelectedShopifyCollections
              "
              v-else
              class="btn d-flex px-3 justify-content-center align-items-center py-2 shopify-btn"
              @click="syncAllCollections()">
              <div v-if="isLoadingSync" class="d-flex align-items-center">
                <b-spinner small></b-spinner>
                <span class="ml-1">Syncing...</span>
              </div>

              <span v-else>Sync all</span>
            </button>
          </div>
        </div>
      </div>
    </ModalVue>
    <DeleteFooterModal ref="deleteModal"
      :isLoading="isLoadingDeleteCollection"
      @click="deleteSelectedCollections()"
      :itemLength="selectedQuizellCollections.length"
      name="collections"></DeleteFooterModal>
       <ViewCategoryProducts ref="viewProducts" category="collection"></ViewCategoryProducts>
       <ModalVue ref="syncCollection" title="Sync Collection" size="md" id="syncCollection">

        <div class="p-4">
<b-form-checkbox
      
                name="withVariant"
               small
             v-model="syncCollectionWithVariant"
                class="checkbox d-flex align-items-center cursor-pointer">
               Sync collection products with variants.
                </b-form-checkbox
              >
        <div class="pt-3" style="font-size:14px;color: #8b8b8b;">
            This may take some time, you can continue other tasks without interruption.
        </div>
          <div class="d-flex  justify-content-center mt-2">
            <Button :isLoading="isLoadingSyncCollection" @click="syncCollectionHandle()">Sync Collection</Button>
          </div>
        </div>
       </ModalVue>
          <AddCollectionModal ref="EditCollectionModal" :editMode="true" :categoryTitle="{singular:'collection', plural:'collections'}" @submit="saveCollection($event,true)" :isLoading="isLoadAddCollection" ></AddCollectionModal>

  </div>
 
</template>
<script>
import EmptyTable from "../Layout/EmptyTable.vue";
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import Button from "../Layout/Button.vue";
import BorderButton from "../Layout/BorderButton.vue";
import ShopifyPagination from "../Layout/ShopifyPagination.vue";
import FilterBox from "../Layout/FilterBox.vue";
import HeaderVue from "../Layout/Header.vue";
import ModalVue from "../Layout/Modal.vue";
import ProgressbarVue from "../Layout/Progressbar.vue";
import axios from "axios";
import AddCollectionModal from "./modal/AddCategory.vue";
import DeleteFooterModal from "../Layout/DeleteFooterModal.vue";
import ViewCategoryProducts from './ViewCategoryProducts.vue';
import SyncWixCollections from './modal/SyncWixCollections.vue';
import SyncBigCommerceCollections from "./modal/SyncBigCommerceCollections.vue";
import QuizellPagination from "../Layout/QuizellPagination.vue";
export default {
  components: {
    FilterBox,
    EmptyTable,
    SyncBigCommerceCollections,
    HeaderVue,
    QuizellPagination,
    ShopifyPagination,
    ModalVue,
    Button,
    BorderButton,
    ProgressbarVue,
    AddCollectionModal,
    DeleteFooterModal,
    ViewCategoryProducts,
    SyncWixCollections
  },
  data() {
    return {
      titleInput:'',
      syncCollectionWithVariant:false,
      syncCollection:null,
      editCollectionId:null,
      isLoadAddCollection:false,
      selectAllQuizellCollections: false,
      selectedQuizellCollections: [],
      searchCollectionText: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 10,
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        endCursor: "",
        startCursor: "",
      },
      isLoadingShopifyCollections: false,
      isLoadingShopifyCollectionFirstTime: false,
      shopifySelectAllCheck: false,
      shopifySelectedCollections: [],
      fields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },

        {
          key: "title",
          label: "Title",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
      

        {
          key: "actions",
          label: "Actions",
          tdClass: "align-middle data-Table d-flex justify-content-end cursor-pointer",
          thClass: "bg-table-heading text-right",
        },
      ],
      shopifyCollections: [],
      shopifyCollectionsFields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "title",
          label: "Collection",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },

        {
          key: "productsCount",
          label: "Total Products",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
      ],
      items: [],
      isLoadingFirstTime: true,
      isLoadingSelectedShopifyCollections: false,
      isLoadingSyncCollection:false,
      isLoadingDeleteCollection: false,
      isLoadingSync: false,
      isLoading: false,
      collectionProducts:[],
      queryObject: {
        first: 50,
        last: 50,
        searchQuery: "",
        after: "",
        before: "",
      },
    };
  },
  watch: {
    items: {
      handler(newValue) {
        if (
          newValue.every((collection) =>
            this.selectedQuizellCollections.includes(collection.id)
          ) &&
          !newValue
        )
          this.selectAllQuizellCollections = true;
        else this.selectAllQuizellCollections = false;
      },
      deep: true,
    },
    titleInput() {
      this.handleCollectionSearchDebounced(
        
      );
    },
    shopifySelectedCollections: {
      handler(newVal) {
        if (newVal.length > 0 && this.shopifyCollections.length > 0)
          this.shopifySelectAllCheck = this.shopifyCollections.every(
            (collection) => newVal.includes(collection)
          );
        else this.shopifySelectAllCheck = false;
        // newVal.length === this.shopifyCollections.length;
      },
      deep: true,
    },
    shopifyCollections: {
      handler(newVal) {
        const collections = newVal;
        if (
          collections.length > 0 &&
          this.shopifySelectedCollections.length > 0
        )
          this.shopifySelectAllCheck = collections.every((collection) =>
            this.shopifySelectedCollections.includes(collection)
          );
        else this.shopifySelectAllCheck = false;
        // this.shopifySelectAllCheck = newVal.length === this.shopifySelectedCollections.length;
      },
      deep: true,
    },
    '$route.query.title'(newValue) {
      this.currentPage=1;
      this.titleInput=newValue;
      this.searchCollectionText=newValue
      
    },
    '$route.query.page'(newValue) {
      this.currentPage= newValue ? parseInt(newValue) : 1;
    },
    '$route.query.perPage'(newValue) {
      this.perPage= newValue ? parseInt(newValue) : 10;
    },
  },
  computed: {
    ...mapGetters(["getNavbarData"]),
    shopifyAllCollectionsIds() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.shopifyCollections.map((collection) => collection.id);
    },
    getUserType(){
      
      return this.getNavbarData.userType;
    }
  },
  methods: {
    applyFilters() {
      const queryParams = {};
      if (this.titleInput) queryParams.title = this.searchCollectionText =this.titleInput;
      queryParams.perPage=this.perPage;
      queryParams.page=this.currentPage;
      this.$router.replace({ query: queryParams });
      this.getCollections()
    },
    updatePerPage(e){
      this.currentPage=1;
      this.perPage=e;
      this.applyFilters();
    },
    updatePagination(e){
      this.currentPage=e;
      this.applyFilters();
    },
    async syncCollectionHandle(){
      this.isLoadingSyncCollection=true;
      
 await axios.post('/collection/'+this.syncCollection.id+'/sync-shopify-products',{ withVariant:this.syncCollectionWithVariant}).then((response)=>{
        this.$toasted.show(response.data.message, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
         
          this.$refs.syncCollection.close();
          this.getCollections();
      }).catch(()=>{
        this.$toasted.show("Error occurred.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
      })
       this.isLoadingSyncCollection=false;
    },
    async saveCollection(data,editMode=false){
       let path = editMode?'collection/update':'collection/save'
       if(editMode) data.collection_id=this.editCollectionId
      this.isLoadAddCollection=true
     await axios.post(path,data).then(()=>{
        this.$toasted.show("Collection saved successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          if(editMode)
           this.$refs.EditCollectionModal.close();
          else
          this.$refs.addCollectionModal.close();
          this.getCollections();
      }).catch((error)=>{
        let {title} = error.response.data.errors
        if(title){
          this.$toasted.show(title, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        } else{
          this.$toasted.show("Error occurred.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      })
      this.isLoadAddCollection=false
    },

    toggleQuizellCollections() {
      if (this.selectAllQuizellCollections)
        this.selectedQuizellCollections = [
          ...new Set([
            ...this.selectedQuizellCollections,
            ...this.items.map((collection) => collection.id),
          ]),
        ];
      else this.selectedQuizellCollections = [];
    },
    async deleteSelectedCollections() {
      this.isLoadingDeleteCollection = true;
      await axios
        .post("/collection/delete", {
          collection_ids: this.selectedQuizellCollections,
        })
        .then(() => {
          this.$refs.deleteModal.close();
          this.currentPage>1&&this.selectedQuizellCollections==this.items?this.updatePagination(this.currentPage):this.getCollections()
          this.selectedQuizellCollections = []
          this.selectAllQuizellCollections = false;
          this.$toasted.show("Collections deleted successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        })
        .catch((e) => {
          this.$toasted.show("Error occurred while deleting collections", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          console.log(e);
        });
      this.isLoadingDeleteCollection = false;
    },
  
    openShopifyCollectionModal() {
      (this.queryObject = {
        first: 50,
        last: 50,
        searchQuery: "",
        after: "",
        before: "",
      }),
        (this.isLoadingShopifyCollectionFirstTime = true);
        this.shopifySelectedCollections=[];
        this.shopifySelectAllCheck=false;
      this.$refs.Modal.open();
      this.getCollectionsFromShopify();
    },
    async syncSelected() {
      this.isLoadingSelectedShopifyCollections = true;
      await axios
        .post("/collection/save/selected", {
          collections: this.shopifySelectedCollections,
        })
        .then(() => {
          this.$refs.Modal.close();
          this.getCollections();
          this.$toasted.show("Collections sync successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        })
        .catch((e) => {
          this.$toasted.show("Error occurred while syncing collections", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          console.log(e);
        });
      this.isLoadingSelectedShopifyCollections = false;
    },
    searchCollectionQueryHandle(e) {
      this.queryObject.after = "";
      this.queryObject.before = "";
      this.queryObject.searchQuery = e;
      this.handleSearchDebounced();
    },
    shopifyCollectionSelectAllToggle() {
      this.shopifySelectedCollections = [];
      if (this.shopifySelectAllCheck) {
        this.shopifySelectedCollections = this.shopifyCollections;
      }
    },
    async syncAllCollections() {
      this.isLoadingSync = true;
      this.progress = 100;
      setTimeout(() => {
        this.$refs.progressbar.start();
      }, 1000);

      await axios
        .post("/collection/sync/store")
        .then(() => {
          this.$refs.progressbar.stop();
          this.$refs.Modal.close();
          this.getCollections();
          this.$toasted.show("Collections sync successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        })
        .catch((e) => {
          this.$refs.progressbar.error();
          console.log(e);
          this.$toasted.show("Error occurred while syncing collections. ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        });

      this.progress = 100;
      setTimeout(() => {
        this.isLoadingSync = false;
      }, 2000);
    },
    async getCollectionsFromShopify() {
      this.isLoadingShopifyCollections = true;

      const data = {
        // searchQuery: `GetCollections($searchQuery: String!, $first: Int!, $after: String) { collections(first: $first, query: $searchQuery, after: $after) { edges { node { id title productsCount } } pageInfo { endCursor startCursor hasNextPage hasPreviousPage } } }`,
        variables: this.queryObject,
      };
      await axios
        .post("collection/search", data)
        .then((response) => {
          if (response.data.status == "success") {
            this.pageInfo = response.data.data.pageInfo;
            this.shopifyCollections = response.data.data.edges.map(
              (collection) => {
                return {
                  title: collection.node.title,
                  id: collection.node.id,
                  productsCount: collection.node.productsCount,
                };
              }
            );
          } else {
            this.$toasted.show("Error occurred getting collections. ", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoadingShopifyCollections = false;
      this.isLoadingShopifyCollectionFirstTime = false;
    },
    async getCollections() {
      this.isLoading = true;
      await axios
        .get('/collection/list',{params:{per_page:this.perPage,page:this.currentPage,title:this.searchCollectionText}})
        .then((response) => {
          this.items = response.data.data.data;
          this.totalRows = response.data.data.total;
          this.perPage = response.data.data.per_page;
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoadingFirstTime = false;
      this.isLoading = false;
    },

    handleSearchDebounced: debounce(function () {
       this.getCollectionsFromShopify();
    
    }, 500), // 500ms debounce time, adjust as needed
    handleCollectionSearchDebounced: debounce(function () {
       this.applyFilters()
    }, 500),
  },
  mounted() {
    this.getCollections();
  },
  created(){
    const query = this.$route.query;
    this.titleInput = this.searchCollectionText = query.title || '';
    this.currentPage= parseInt(query.page) || 1;
    this.perPage= parseInt(query.perPage) || 10;
  }
};
</script>
<style scoped>
.shopify-btn:active {
  background-color: #002e25;
  color: #ffffff;
  border-color: #002e25;
}
.shopify-btn {
  appearance: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #008060;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  font-size: 14px;

  transition: 150ms ease;
  transition-property: background-color, border-color;
  display: inline-block;

  border-radius: 6px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
.leadTable >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.leadTable >>> thead tr {
  border-radius: 10px;
}
.leadTable >>> thead th {
  border: none;
  vertical-align: middle !important;
}
.loader {
  min-height: 40px;
}
.product-image {
  height: 40px;
  width: 40px;
  /* border: 4px solid rgb(255, 255, 255); */
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  /* padding: 0.25rem; */
  /* background-color: #fff; */
}
.customPagination >>> li > button {
  color: var(--primary-color);
}

.customPagination >>> li.active > button {
  color: white;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color);
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: var(--primary-color);
}
#collection-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#collection-detail >>> button::after {
  display: none;
}
#collection-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#collection-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#collection-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--primary-color);
}
</style>